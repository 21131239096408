import "./src/styles/reset.css"
import "./src/styles/global.css"
import "./src/styles/home.css"
import "./src/styles/nav.css"
import "./src/styles/apropos.css"
import "./src/styles/collaborateurs.css"
import "./src/styles/collaborateur.css"
import "./src/styles/services.css"
import "./src/styles/contact.css"
import "./src/styles/overlay.css"
import "./src/styles/slider.css"

import React from "react"
import LangProvider from "./src/hooks/lang.js"
var contentful = require("contentful")

var contentfulClient = contentful.createClient({
  space: "qolx2ldwt0s3",
  accessToken: "qKaaPtGG4MuD-lEcMDYQhiHjMs4vJZPlwaOHi6dIwhQ",
})
if (typeof window !== undefined) {
  window.contentfulClient = contentfulClient
}

export const wrapPageElement = ({ element, props }) => {
  return <LangProvider {...props}>{element}</LangProvider>
}
