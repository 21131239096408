import React, { Component, createContext } from "react"

const LangContext = createContext()
class LangProvider extends Component {
  state = {
    lang: "fr-CA",
    toggleLang: () => {
      if (this.state.lang === "fr-CA") {
        this.setState({ lang: "en-CA" })
      } else {
        this.setState({ lang: "fr-CA" })
      }
    },
  }
  render() {
    return (
      <LangContext.Provider value={this.state}>
        {this.props.children}
      </LangContext.Provider>
    )
  }
}

const LangConsumer = LangContext.Consumer

export default LangProvider
export { LangConsumer, LangContext }
